import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import particlesConfig from "config/particle-config";
import Split from "components/Split";
import DarkTheme from "layouts/Dark";
import initIsotope from "common/initIsotope";

import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import FreelancreIntro from "components/Freelancre-intro/freelancre-intro";
import Services5 from "components/Services5/services5";
import WorksStyle4 from "components/Works-style4/works-style4";
import AboutUs5 from "components/About-us5/about-us5";
import FullTestimonials from "components/Full-testimonials/full-testimonials";
import Blogs2 from "components/blogs/Blogs2/blogs2";
import SContactForm from "components/s-contact-form/s-contact-form";

const Demos = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [pageLoaded, setPageLoaded] = React.useState(false);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  React.useEffect(() => {
    setPageLoaded(true);
    if (pageLoaded) {
      window.addEventListener("load", () => {
        setTimeout(() => {
          if (pageLoaded) initIsotope();
          document
            .querySelector("#particles-js canvas")
            ?.style.removeProperty("position");
        }, 500);
      });
    }

    setTimeout(() => {
      initIsotope();
      document
        .querySelector("#particles-js canvas")
        ?.style.removeProperty("position");
    }, 500);
  }, [pageLoaded]);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <FreelancreIntro />
      <Services5 />
      <WorksStyle4 />
      <AboutUs5 />
      <Blogs2 />
      <FullTestimonials showHead />
      <SContactForm noLine />
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Tyler Larsson</title>
      <link rel="stylesheet" href="demo.css" />
    </>
  );
};

export default Demos;
